import React, { useEffect, useState } from "react";
import Layout from "../../shared/Layout";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import LoadingButton from '@mui/lab/LoadingButton';
import { useRoboApi } from './RoboApi';
import { Grid2 } from "@mui/material";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import HomeIcon from '@mui/icons-material/Home';

const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const Robot = () => {
  const { getConfig, updateConfig, runCommand } = useRoboApi();
  const [selectedTime, setSelectedTime] = useState(dayjs());
  const [checkedDays, setCheckedDays] = useState(
    weekdays.reduce((acc, day) => ({ ...acc, [day]: false }), {})
  );
  const [loading, setLoading] = useState(false);

  useEffect(() =>{
    fetchConfig();
  },[]);

  const fetchConfig = async () => {
    const response = await getConfig();
    if (response.success && response.data?.value) {
      const { days, executionTime } = response.data.value;

      const formattedTime = dayjs(executionTime, "HH:mm:ss").format("HH:mm");

      const updatedDays = weekdays.reduce((acc, day) => ({
        ...acc,
        [day]: days.includes(day.toUpperCase()),
      }), {});

      setCheckedDays(updatedDays);
      setSelectedTime(dayjs(formattedTime, "HH:mm"));
    } else {
      console.error("Error fetching config:", response.error);
    }
  };

  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedDays((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSave = async () =>
  {
    setLoading(true);
    const config =
    {
      
      days: weekdays.filter((day) => checkedDays[day]).map((day) => day.toLocaleUpperCase()),
      executionTime:  dayjs(selectedTime, "HH:mm").format("HH:mm") + ":00"
    }

    await updateConfig(config);
    setLoading(false);
  }

  const handleRunCommand = async (cmd) =>
  {
    setLoading(true);
    await runCommand(cmd);
    setLoading(false);
  }

  return (
    <Layout additionalTex>
      <Grid2
        container
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={1}
        square
        style={{ maxWidth: "90vw", width: "100%", maxHeight: "90vh",  flexDirection: "column",
          alignItems: "center"}}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minwidth: "100%"
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
              label="Select Time"
              value={selectedTime}
              onChange={handleTimeChange}
              ampm={false}
              textField={(params) => <input {...params} />}
            />
          </LocalizationProvider>

          {/* Weekdays Checkboxes */}
          <Box sx={{width: "100%"}}>
            <FormGroup sx={{ mt: 4,  display: "flex", flexDirection: "row", alignItems: "center", minWidth: "100%", justifyContent: "center" }}>
              {weekdays.map((day) => (
                <FormControlLabel
                  key={day}
                  control={
                    <Checkbox
                      name={day}
                      checked={checkedDays[day]}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={day}
                />
              ))}
            </FormGroup>
            <LoadingButton 
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2, maxWidth: "40vw" }}
              onClick={handleSave}
              loading={loading}
            >
              Save
            </LoadingButton>
            <LoadingButton 
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2, maxWidth: "40vw" }}
              onClick={() => handleRunCommand("start")}
              loading={loading}
              startIcon={<PlayCircleOutlineIcon/>}
            >
              Start
            </LoadingButton>
            <LoadingButton 
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2, maxWidth: "40vw" }}
              onClick={() => handleRunCommand("goHome")}
              loading={loading}
              startIcon={<HomeIcon/>}
            >
              Home
            </LoadingButton>
          </Box>
        </Box>
      </Grid2>
    </Layout>
  );
};

export default Robot;
