import React, { useEffect } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useLocation } from "react-router-dom";
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useAuth } from "../Auth/authprovider";
import dickeItem from "../pictures/dicke.ico";
import Avatar from "@mui/material/Avatar";
import PERMISSIONS from "../Auth/permissions";

export const HomeBar = ({ handleOpenChangePsw, additionalText }) => {

    const { logout, user } = useAuth();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => { }, [additionalText]);


    const getPageTitle = () => {
        if (!location.pathname) return 'Page';
        const parts = location.pathname.split('/');
        const lastPart = parts.pop();
        return lastPart.charAt(0).toUpperCase() + lastPart.slice(1);

    };


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenu2 = (event) => {
        setAnchorE2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorE2(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logout();
        handleClose();
    };

    const handleGoToUsers = () => {
        navigate("/family/users");
    };

    const handleGoConvert = () => {
        navigate("/family/convert");
    };

    const handleGoHome = () => {
        navigate("/family/home");
    };

    const handleGoWeather = () => {
        navigate("/family/Weather");
    };

    const handleGoRobot = () => {
        navigate("/family/robot");
    };

    return (
        <AppBar position="static" style={{ top: '0px', position: 'relative' }}>
            <Toolbar>
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <img src={dickeItem} alt="Custom Icon" style={{ width: '100%', height: '100%', cursor: 'pointer' }} onClick={handleMenu2} />
                </Avatar>
                <div style={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {getPageTitle()}
                    </Typography>
                    {
                        additionalText &&
                        (
                            <Typography variant="caption" component="div" sx={{ flexGrow: 1 }}>
                                {additionalText}
                            </Typography>
                        )
                    }
                </div>
                <div>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <Typography component="div" sx={{ flexGrow: 1, marginRight: '10px' }}>
                            {user.username}
                        </Typography>
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {user.role.includes(PERMISSIONS.ADMIN) && (
                            <MenuItem onClick={handleGoToUsers}>User</MenuItem>)
                        }
                        <MenuItem onClick={() => { handleOpenChangePsw(); setAnchorEl(null); }}>Change Password</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>

                    <Menu
                        id="menu-nav"
                        anchorEl={anchorE2}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorE2)}
                        onClose={handleClose2}
                    >
                        <MenuItem onClick={handleGoHome}>Home</MenuItem>
                        <MenuItem onClick={handleGoConvert}>Converter</MenuItem>
                        <MenuItem onClick={handleGoWeather}>Weather</MenuItem>
                        <MenuItem onClick={handleGoRobot}>Robot</MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    );
}

export default HomeBar;