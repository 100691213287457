import React, { useState, useEffect } from "react";
import Layout from "../../shared/Layout";
import { useWeatherApi } from "./WeatherApi";
import Grid2 from "@mui/material/Grid2";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import CloudSharpIcon from '@mui/icons-material/CloudSharp';
import SpeedSharpIcon from '@mui/icons-material/SpeedSharp';
import { LineChart } from '@mui/x-charts/LineChart';
import Typography from '@mui/material/Typography';

const Weather = () => {

  const { getLast, getLastWeek, getLastMonth, getAll, getToday } = useWeatherApi();
  const [last, setLast] = useState();
  const [chartData, setChartData] = useState({ dates: [], temperatures: [] });
  const [range, setRange] = useState("Today");
  const [type, setType] = useState("Temperature");

  useEffect(() => {
    getLastFromApi();

  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getLastFromApi();
      fetchTemp(range);
      console.log("NOW!");
    }, 100000); 
    return () => clearInterval(interval);
  }, [range]);
  

  useEffect(() => {
    fetchTemp();
  }, []);

  const getLastFromApi = async () => {
    let resp = await getLast();

    if (resp.success) {

      var date = new Date(resp.data.date);

      const optionsTime = {
        hour: '2-digit',
        minute: '2-digit',
      };
      resp.data.date = date.toLocaleDateString('de-DE', optionsTime);

      setLast(resp.data);
    }
  };

  const fetchTemp = async (range = "Today") => {

    let resp = chartData;
    if (range === "Last Week") {
      resp = await getLastWeek();
    }
    else if (range === "Last Month") {
      resp = await getLastMonth();
    }
    else if (range === "Today") {
      resp = await getToday();
    }
    else {
      resp = await getAll();
    }


    if (resp.success) {
      const optionsTime = {
        hour: '2-digit',
        minute: '2-digit',
      };
      const dates = resp.data.map((entry) => ({
        value: new Date(entry.date),
        label: new Date(entry.date).toLocaleDateString('de-DE', optionsTime)
      }));
      const temperatures = resp.data.map((entry) => entry.tempreature);
      const humidity = resp.data.map((entry) => entry.humidity);
      const airPressure = resp.data.map((entry) => entry.airPressure);
      setChartData({ dates, temperatures, humidity, airPressure });
    }
  };


  const handleChangeRange = (event) => {
    setRange(event.target.value);
    fetchTemp(event.target.value);
  }

  const handleChangeType = (event) => {
    setType(event.target.value);
  }

  const chart = () => {
    return (
      <div style={{ flex: '1 1 0', margin: '0px', position: 'relative', minWidth: '330px', minHeight: '50vh', width: '100%' }}>
        <LineChart
        margin={{right: 55, left: 35 }}
          sx={{ width: '100%', height: '100%' }}
          bottomAxis={{
            labelStyle: {
              fontSize: 14,
              transform: `translateY(${5 * Math.abs(Math.sin((Math.PI * 45) / 180))
                }px)`
            },
            tickLabelStyle: {
              angle: 45,
              textAnchor: 'start',
              fontSize: 10,
            },
          }}


          leftAxis={{
            labelStyle: {
              fontSize: 14,
            },
            tickLabelStyle: {
              fontSize: 10,
            },
          }}


          xAxis={[{
            data: chartData.dates.map(d => d.value),
            valueFormatter: (value) => {
              const optionsDate = {
                day: '2-digit',
              };

              const optionsTime = {
                hour: '2-digit',
                minute: '2-digit',
              };
              const date = new Date(value);
              const day = date.toLocaleDateString('de-DE', optionsDate);
              const time = date.toLocaleTimeString('de-DE', optionsTime);
              return `${day}. ${time}`;
            }
          }]}
          series={[
            {
              data: type === "Temperature" ? chartData.temperatures : type === "Humidity" ? chartData.humidity : chartData.airPressure,
              showMark: ({ index }) => false,
            },
          ]}
        />
      </div>
    );
  }

  const Item = styled(Paper)(({ theme }) => ({
    display: "flex",
    flexDirection: 'row',
    backgroundColor: '#6082B6',
    ...theme.typography.fontSize,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#ffffff',
  }));

  return (
    <>
      <Layout additionalText={last ? last.date : ''}>
        {last && (
          <>
            <Stack direction="row" spacing={2} position={"absolute"} top={"10%"} width={"100%"} justifyContent={"center"}>
              <Item>
              <DeviceThermostatIcon style={{ height: '100%', fontSize: "3vw" }} />
                <Typography sx={{fontSize: {sm: "3vw"}}}>
                  {" " + last.tempreature + "°C"}
                </Typography>
              </Item>
              <Item>
              <CloudSharpIcon style={{ height: '100%', fontSize: "3vw", marginLeft: "1vw", marginRight: "1vw" }} />
              <Typography sx={{fontSize: {sm: "3vw"}}}>
                {" " + last.humidity + "%"}
              </Typography>
              </Item>
              <Item>
              <SpeedSharpIcon style={{ height: '100%', fontSize: "3vw", marginLeft: "1vw", marginRight: "1vw"}} />
              <Typography sx={{fontSize: {sm: "3vw"}}}>
                {" " + last.airPressure + " bar"}
                </Typography>
              </Item>
            </Stack>
          </>
        )}

        <Grid2 xs={12} sm={8} md={5} component={Paper} elevation={1} square style={{ maxWidth: '90vw', width: "100%" }}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div>
            <Select
              labelId="type-select-label"
              id="type"
              value={type}
              label="Type"
              onChange={handleChangeType}
              style={{marginRight:'1vw'}}
            >
              <MenuItem value={"Temperature"}>Temperature</MenuItem>
              <MenuItem value={"Humidity"}>Humidity</MenuItem>
              <MenuItem value={"Air Pressure"}>Air Pressure</MenuItem>
            </Select>
            <Select
              labelId="range-select-label"
              id="range"
              value={range}
              label="Range"
              onChange={handleChangeRange}
            >
              <MenuItem value={"Today"}>Today</MenuItem>
              <MenuItem value={"Last Week"}>Last Week</MenuItem>
              <MenuItem value={"Last Month"}>Last Month</MenuItem>
              <MenuItem value={"All"}>All</MenuItem>
            </Select>
            </div>
            {chart()}
          </Box>
        </Grid2>
      </Layout>
    </>
  );
};

export default Weather;
