import React, { useState, useEffect } from "react";
import Layout from "../../shared/Layout";
import Grid2 from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LoadingButton from '@mui/lab/LoadingButton';
import { useConvertApi } from "./ConvertApi";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import Alert from '@mui/material/Alert';
import { startSignalRConnection, connection } from "./ConvertHub";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { useFileApi } from "../Home/FileApi";


const Convert = () => {

  const { downloadFile} = useFileApi();
  const { convert } = useConvertApi();
  const [url, setUrl] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarIsError, setSnackbarIsError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [downloads, setDownloads] = useState([]);


  useEffect(() => {
    if (!connection.connectionId) {
      startSignalRConnection();
    }

      const handleUpdate = (newDownload) => {
        
        if (newDownload.finished)
        {
          downloadFile([newDownload.filePath]);
        }

        setDownloads(prevDownloads => {
          const existingIndex = prevDownloads.findIndex(d => d.id === newDownload.id);
          const updatedDownloads = [...prevDownloads];
    
          if (newDownload.finished) {
            return updatedDownloads.filter(d => d.id !== newDownload.id);
          }
    
          if (existingIndex > -1) {
            updatedDownloads[existingIndex] = newDownload; // Update existing download
          } else {
            updatedDownloads.push(newDownload); // Add new download
          }
    
          return updatedDownloads;
        });
      };

      connection.on("update", handleUpdate);
    

    return () => {
      connection.off("update", handleUpdate);
    };

  }, []);

  const handleOnClick = async () => {

    if (!connection.connectionId) {
      await startSignalRConnection();
    }


    if (!url || url == "") {
      setSnackbarMessage("Please enter a valid URL.");
      setSnackbarIsError(true);
      setSnackbarOpen(true);
      return;
    }
    setLoading(true);
    var resp = await convert(url, connection.connectionId);
    setSnackbarMessage(resp.message);
    setSnackbarIsError(!resp.success);
    setSnackbarOpen(true);
    setUrl("");
    setLoading(false);
  }

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Layout>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={Slide}
      >
        <Alert
          onClose={handleClose}
          severity={snackbarIsError ? "error" : "success"}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <Grid2 xs={12} sm={8} md={5} component={Paper} elevation={1} square style={{ maxWidth: '90vw', width: "100%" }}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Convert 2 mp3
          </Typography>
          <TextField
            onChange={e => setUrl(e.target.value)}
            variant="outlined"
            margin="normal"
            fullWidth
            label="Url"
            name="Url"
            autoFocus
            value={url}
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            loading={isLoading}
            onClick={handleOnClick}
          >
            Convert
          </LoadingButton >
          <List sx={{ width: '100%', bgcolor: 'background.paper', maxHeight: "50vh", overflow: "auto" }}>
            {downloads.map(download => (
              <ListItem key={download.id}>
                <ListItemText primary={download.title} secondary={""} />
                <Box sx={{ width: '70%' }}>
                  {`${download.message}: ${download.percent}%`}
                  <LinearProgress variant="determinate" value={download.percent} />
                </Box>
              </ListItem>
            ))}
          </List>


          <Box mt={5}></Box>
        </Box>
      </Grid2>
    </Layout>
  );
};

export default Convert;
