import Cookies from 'js-cookie';

export const useRoboApi = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const getConfig = async () => {
        const storedToken = sessionStorage.getItem('token') || Cookies.get('token');
        try {
            const response = await fetch(`${apiUrl}/api/Robo`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${storedToken}`
                }
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }

            const data = await response.json();
            return { success: true, data: data };
        } catch (error) {
            return { success: false, error: `Error fetching configuration: ${error.message}` };
        }
    };

    const updateConfig = async (config) => {
        const storedToken = sessionStorage.getItem('token') || Cookies.get('token');
        try {
            const response = await fetch(`${apiUrl}/api/Robo/config`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${storedToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(config),
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }

            return { success: true };
        } catch (error) {
            return { success: false, error: `Error updating configuration: ${error.message}` };
        }
    };

    const runCommand = async (command) => {
        const storedToken = sessionStorage.getItem('token') || Cookies.get('token');
        try {
            const response = await fetch(`${apiUrl}/api/Robo/command?commmand=${encodeURIComponent(command)}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${storedToken}`,
                }
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }

            return { success: true };
        } catch (error) {
            return { success: false, error: `Error running command: ${error.message}` };
        }
    };

    return { getConfig, updateConfig, runCommand };
};
