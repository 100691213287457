import Cookies from 'js-cookie';

export const useWeatherApi = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const getLast = async () => {
        const storedToken = sessionStorage.getItem('token') || Cookies.get('token');
        try {
            const response = await fetch(`${apiUrl}/api/Weather/last`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${storedToken}`
                }
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }

            const data = await response.json();
            return { success: true, data: data};
        } catch (error) {
            return { success: false, error: `Error fetching directory: ${error.message}` };
        }
    };


    const getLastWeek = async () => {
        const storedToken = sessionStorage.getItem('token') || Cookies.get('token');
        try {
            const response = await fetch(`${apiUrl}/api/Weather/lastWeek`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${storedToken}`
                }
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }

            const data = await response.json();
            return { success: true, data: data};
        } catch (error) {
            return { success: false, error: `Error fetching directory: ${error.message}` };
        }
    };

    const getLastMonth = async () => {
        const storedToken = sessionStorage.getItem('token') || Cookies.get('token');
        try {
            const response = await fetch(`${apiUrl}/api/Weather/lastMonth`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${storedToken}`
                }
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }

            const data = await response.json();
            return { success: true, data: data};
        } catch (error) {
            return { success: false, error: `Error fetching directory: ${error.message}` };
        }
    };


    const getAll = async () => {
        const storedToken = sessionStorage.getItem('token') || Cookies.get('token');
        try {
            const response = await fetch(`${apiUrl}/api/Weather/all`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${storedToken}`
                }
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }

            const data = await response.json();
            return { success: true, data: data};
        } catch (error) {
            return { success: false, error: `Error fetching directory: ${error.message}` };
        }
    };

    const getToday = async () => {
        const storedToken = sessionStorage.getItem('token') || Cookies.get('token');
        try {
            const response = await fetch(`${apiUrl}/api/Weather/today`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${storedToken}`
                }
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }

            const data = await response.json();
            return { success: true, data: data};
        } catch (error) {
            return { success: false, error: `Error fetching directory: ${error.message}` };
        }
    };

    return { getLast, getLastWeek, getLastMonth, getAll, getToday};
};
