import * as signalR from "@microsoft/signalr";

const connection = new signalR.HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_API_URL + "/progressHub")
    .build();

async function startSignalRConnection() {
    try {
        await connection.start();
    } catch (err) {
        console.error("Error connecting to SignalR hub:", err);
    }
}

export { connection, startSignalRConnection };
