import './css/App.css';
import * as React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import FourierTransformation from './FourierTransformation/FourierTransformation';
import Login from './Family/pages/Login';
import Authorization from './Family/Auth/Authorization'
import PERMISSIONS  from './Family/Auth/permissions';
import Home from './Family/pages/Home/Home';
import UsersManagement from './Family/pages/UserManagement'; 
import Convert from './Family/pages/Convert/Convert';
import Weather from './Family/pages/Weather/Weather';
import Robot from './Family/pages/Robot/Robot';



function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route element={<Authorization permissions={[PERMISSIONS.USER, PERMISSIONS.ADMIN]} />}>
            <Route path="/family/home" element={<Home/>} />
            <Route path="/family/convert" element={<Convert/>} />
            <Route path="/family/weather" element={<Weather/>}/>
            <Route path="/family/robot" element={<Robot/>}/>
          </Route>
          <Route element={<Authorization permissions={[PERMISSIONS.ADMIN]} />}>
            <Route path="/family/users" element={<UsersManagement/>} />
          </Route>
          <Route path='/family/login' element={<Login />} />
          <Route path='/' element={<FourierTransformation/>}/>
          <Route path="*" element={<Navigate to="/family/home" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
