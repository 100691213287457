import Cookies from 'js-cookie';

export const useConvertApi = () => 
{
    const apiUrl = process.env.REACT_APP_API_URL;
    
    const convert = async (url, connectionId) =>
    {
        const storedToken = sessionStorage.getItem('token') || Cookies.get('token');
        try {
            const response = await fetch(`${apiUrl}/api/converter`, {
                method: "Post",
                headers: {
                    "Authorization": `Bearer ${storedToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({Url: url, ConnectionId : connectionId}),
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }
            var body = await response.json();
            return { success: body.success, message: body.message};
        } catch (error) {
            return { success: false, error: `Error downloading file: ${error.message}` };
        }
    }

    return {convert}
};